import { atom } from 'recoil'

export const shrimpSalesDateFilterState = atom<Date>({
  key: 'shrimpSalesDateFilterState',
  default: new Date(),
})

export const shrimpSalesDateTableFilterState = atom<Date | undefined>({
  key: 'shrimpSalesDateTableFilterState',
  default: undefined,
})

export const selectedShrimpSalesState = atom<number | undefined>({
  key: 'selectedShrimpSalesState',
  default: undefined,
})
