import { BaseResponse } from 'common/types/api'
import axios from 'core/api/axios'

import { buildUrlWithParams } from 'utils/url'

import {
  ShrimpSales,
  ShrimpSalesRequestVariables,
  RecordShrimpSalesVariables,
  UploadBillResponse,
  ShrimpSalesSummary,
  ShrimpSalesType,
  UpdateShrimpSalesVariables,
  Partial,
} from './types'

export const recordShrimpSales = (variables: RecordShrimpSalesVariables) =>
  axios.post(`/v2/farms/ponds/cycles/${variables.cycleId}/shrimp-sales`, { ...variables.body })

export const getShrimpSales = (variables: ShrimpSalesRequestVariables) =>
  axios.get<BaseResponse<ShrimpSales[]>>(
    buildUrlWithParams(`/v2/farms/ponds/cycles/${variables.cycleId}/shrimp-sales`, {
      ...variables.params,
    })
  )

export const deleteShrimpSales = (id: number) =>
  axios.delete(`/v2/farms/ponds/cycles/shrimp-sales/${id}`)

export const getShrimpSalesSummary = (cycleId: string) =>
  axios.get<BaseResponse<ShrimpSalesSummary>>(
    `/v2/farms/ponds/cycles/${cycleId}/shrimp-sales/summary`
  )

export const uploadBill = (data: FormData) =>
  axios.post<BaseResponse<UploadBillResponse>>(
    '/v2/farms/ponds/cycles/shrimp-sales/upload_bill',
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )

export const getShrimpSalesTypes = () =>
  axios.get<BaseResponse<ShrimpSalesType[]>>('/v2/shrimp-sales-type')

export const updateShrimpSales = (variables: UpdateShrimpSalesVariables) =>
  axios.patch(`/v2/farms/ponds/cycles/shrimp-sales/${variables.shrimpSalesId}`, {
    ...variables.body,
  })

export const getPartials = (cycleId: string) =>
  axios.get<BaseResponse<Partial[]>>(`/v2/farms/ponds/cycles/${cycleId}/shrimp-sales/partials`)
