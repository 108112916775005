import { useState } from 'react'

const PARTIAL = 'พาเชียล'

export default function PartialAnnotation(shrimpAges: number[]) {
  const [label, setLabel] = useState<Record<number, string>>({})

  return shrimpAges.map((age) => ({
    x: age,
    borderColor: '#919EAB',
    label: {
      mouseEnter: () => {
        const current = label[age] || PARTIAL
        if (current === PARTIAL) {
          setLabel((prev) => ({ ...prev, [age]: `อายุกุ้ง ${age} วัน` }))
        }
      },
      mouseLeave: () => {
        const current = label[age] || PARTIAL
        if (current !== PARTIAL) {
          setLabel((prev) => ({ ...prev, [age]: PARTIAL }))
        }
      },
      borderColor: '#919EAB',
      style: {
        color: '#fff',
        background: '#919EAB',
      },
      text: label[age] || PARTIAL,
    },
  }))
}
